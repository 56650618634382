import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Link from '@material-ui/core/Link';

const propTypes = {
    anchorEl: PropTypes.object,
};

const styles = theme => ({
    paper: {
        zIndex: theme.zIndex.tooltip,
    },
    grow: {
        transformOrigin: 'left top',
    },
});

const CustomDropdown = ({ anchorEl, items, classes, width }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        anchorEl.addEventListener('click', toggleMenu(), false);
        anchorEl.addEventListener('mouseenter', toggleMenu(true), false);
        anchorEl.addEventListener('mouseleave', toggleMenu(false), false);
        return () => {
            anchorEl.removeEventListener('click', toggleMenu, false);
            anchorEl.removeEventListener('mouseenter', toggleMenu, false);
            anchorEl.removeEventListener('mouseleave', toggleMenu, false);
        };
    }, [anchorEl]);

    const toggleMenu = open => e => {
        // do nothing when hovering to the menu. Otherwise hovering from anchor to menu will hide the menu
        const target = e.toElement || e.relatedTarget;
        if (
            target &&
            target.classList &&
            (target.classList.contains('dropdown-menu-list') ||
                target.classList.contains('dropdown-menu-list-item'))
        ) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        setOpen(prevOpen => (typeof open === 'boolean' ? open : !prevOpen));
    };

    return (
        <Popper
            anchorEl={anchorEl}
            open={open}
            placement={width === 'xs' ? 'bottom' : 'bottom-start'}
            transition
            disablePortal
            className={classes.paper}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    id="custom-dropdown"
                    className={classes.grow}
                >
                    <Paper square onMouseLeave={toggleMenu(false)}>
                        <ClickAwayListener onClickAway={toggleMenu(false)}>
                            <MenuList
                                disablePadding
                                className="dropdown-menu-list"
                            >
                                {items.map(({ href, label, newTab }, index) => (
                                    <Link
                                        href={href}
                                        key={index}
                                        target={newTab ? '_blank' : '_self'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <MenuItem className="dropdown-menu-list-item">
                                            {label}
                                        </MenuItem>
                                    </Link>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

CustomDropdown.propTypes = propTypes;

export default withWidth()(withStyles(styles)(CustomDropdown));
