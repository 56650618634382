import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CustomDropdown from '../components/CustomDropdown';
import waitForElement from '../utils/waitForElement';

const themeKeys = [
    'color',
    'fontSize',
    'fontWeight',
    'fontFamily',
    'textTransform',
    'backgroundColor',
];

const pick = keys => object =>
    keys.reduce(
        (acc, key) => (object[key] ? { ...acc, [key]: object[key] } : acc),
        {}
    );

const pickThemeKeys = pick(themeKeys);

const createTheme = ({
    color,
    fontSize,
    backgroundColor,
    fontFamily,
    fontWeight,
    textTransform,
}) =>
    createMuiTheme({
        palette: {
            text: {
                primary: color,
            },
            background: {
                paper: backgroundColor || '#fff',
            },
        },
        typography: {
            useNextVariants: true,
            fontSize: fontSize,
            subheading: {
                fontFamily,
                fontWeight,
                fontSize: fontSize,
                textTransform,
            },
        },
    });

const render = appendTo => {
    if (!window.psCustomDropdownSettings) {
        throw new Error(
            'No psCustomDropdownSettings configuration found on window'
        );
    }

    let holder = document.querySelector('.ps-custom-dropdown-holder');

    if (!holder) {
        holder = document.createElement('span');
        holder.className = 'ps-custom-dropdown-holder';
        document.body.appendChild(holder);
    }

    const dropdownConfigurations = Array.isArray(
        window.psCustomDropdownSettings
    )
        ? window.psCustomDropdownSettings
        : [window.psCustomDropdownSettings];

    // getting background color from parent container since navbar btn is transparent
    // selectors might gonna change with the new navbar implmentation
    const navBar = document.querySelector('nav.navbar');

    const { backgroundColor } = navBar ? window.getComputedStyle(navBar) : {};

    ReactDOM.render(
        <React.Fragment>
            {dropdownConfigurations.map(
                ({ items, tabId, cssSelector, ...rest }) => {
                    const configThemeKeys = pickThemeKeys(rest);
                    const anchorEl = document.querySelector(
                        cssSelector || `.navbar-nav a[href*="${tabId}"]`
                    );

                    if (anchorEl) {
                        const navBtn = anchorEl.querySelector('.nav-menu-btn');

                        const computedThemeKeys = navBtn
                            ? pickThemeKeys(window.getComputedStyle(navBtn))
                            : {};
                        anchorEl.removeAttribute('href');
                        navBtn.innerHTML = navBtn.innerHTML + ' ▾';
                        return (
                            <MuiThemeProvider
                                key={cssSelector || tabId}
                                theme={createTheme({
                                    ...computedThemeKeys,
                                    backgroundColor,
                                    ...configThemeKeys,
                                })}
                            >
                                <CustomDropdown
                                    anchorEl={anchorEl}
                                    items={items}
                                />
                            </MuiThemeProvider>
                        );
                    } else {
                        return null;
                    }
                }
            )}
        </React.Fragment>,
        holder
    );
};

if (!window.psCustomDropdownSettings) {
    throw new Error(
        'No psCustomDropdownSettings configuration found on window'
    );
}

const { cssSelector, tabId } = Array.isArray(window.psCustomDropdownSettings)
    ? window.psCustomDropdownSettings[0]
    : window.psCustomDropdownSettings;

waitForElement({
    selector: cssSelector || `.navbar-nav a[href*="${tabId}"]`,
    resolve: render,
});
