const waitForElement = ({ selector, resolve, onRemove }) => {
    if ('MutationObserver' in window) {
        let lastElem;
        let el = document.querySelector(selector);
        if (el) {
            lastElem = el;
            resolve(el);
        }
        new MutationObserver((mutationRecords, observer) => {
            const elements = Array.from(document.querySelectorAll(selector));
            if (elements.length > 0) {
                elements.forEach(element => {
                    if (lastElem !== element) {
                        lastElem = element;
                        resolve(element);
                    }
                });
            } else if (lastElem) {
                onRemove && onRemove(lastElem);
            }
        }).observe(document.documentElement, {
            childList: true,
            subtree: true,
        });
    } else {
        function waitForElementToDisplay(cssSlector, time) {
            const element = document.querySelector(selector);
            if (element != null) {
                resolve(element);
                return;
            } else {
                setTimeout(function() {
                    waitForElementToDisplay(cssSlector, time);
                }, time);
            }
        }

        waitForElementToDisplay(selector, 500);
    }
};

export default waitForElement;
